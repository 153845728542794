import React, { useState, useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ActionContext from "./context/ActionContext";
import { LOGIN_FETCH } from "./context/types";

export default function LoginPage(props) {
	const dispatch = useContext(ActionContext);
	const [values, setValues] = useState({
		username: "",
		password: "",
		errors: {}
	});

	useEffect(() => {
		document.title = "Login";
	}, []);

	const { username, password, errors } = values;

	const handleChange = name => event => {
		let errors = { ...values.errors };
		delete errors[name];
		setValues({ ...values, [name]: event.target.value, errors: errors });
	};

	const handleSubmit = () => {
		if (
			username.constructor.name !== "String" ||
			username.length <= 0 ||
			username.length >= 80
		) {
			errors.username = "Invalid Username Format";
		}
		if (
			password.constructor.name !== "String" ||
			password.length <= 0 ||
			password.length >= 80
		) {
			errors.password = "Invalid Password Format";
		}
		if (Object.keys(errors).length === 0) {
			dispatch({
				type: LOGIN_FETCH,
				payload: { username: username, password: password }
			});
		}
		setValues({
			password: "",
			username: "",
			errors: errors
		});
	};

	const fieldHasError = fieldName => {
		return errors.hasOwnProperty(fieldName);
	};

	const submitIfEnter = e => {
		if(e.charCode === 13) {
			e.preventDefault();
			e.stopPropagation();
			handleSubmit();
		}
	};

	return (
		<form noValidate autoComplete="off">
			<Grid container spacing={2} justify="center">
				<Grid item xs={2}>
					<TextField
						error={fieldHasError("username") ? true : false}
						helperText={
							fieldHasError("username") ? errors.username : null
						}
						id="username"
						label="Username"
						value={username}
						onChange={handleChange("username")}
						onKeyPress={submitIfEnter}
						margin="normal"
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2} justify="center">
				<Grid item xs={2}>
					<TextField
						error={fieldHasError("password") ? true : false}
						helperText={
							fieldHasError("password") ? errors.password : null
						}
						id="password"
						label="Password"
						value={password}
						type="password"
						onChange={handleChange("password")}
						onKeyPress={submitIfEnter}
						margin="normal"
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2} justify="center">
				<Grid item xs={2}>
					<Button
						variant="contained"
						color="primary"

						onClick={() => handleSubmit()}
					>
						Login
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={2} justify="center">
				<Grid item xs={4} />
			</Grid>
		</form>
	);
}
