import {
	SESSION_EXPIRED,
	LOGIN_FETCH,
	LOGIN_SUCCESSFULL,
	LOGIN_FAILURE,
	DONE_LOADING
} from "../types";
import { loginStore, loginSuccess, loginFailure } from "./authActions";

export default (state, action) => {
	switch (action.type) {
		case LOGIN_FETCH:
			return loginStore(state, action.payload);
		case LOGIN_SUCCESSFULL:
			return loginSuccess(state);
		case LOGIN_FAILURE:
			return loginFailure(state, action.payload);
		case DONE_LOADING:
			return {
				...state,
				isLoading: false
			};
		case SESSION_EXPIRED:
			return {
				...state,
				isLogged: false
			};
		default:
			return state;
	}
};
