import { FETCH_SCHOOLS, UPDATE_SCHOOL, CREATE_SCHOOL, DELETE_SCHOOL } from "../types";
import { fetchSchools, updateSchool, createSchool, deleteSchool } from "./schoolsActions";

export default function SchoolsMiddleware(dispatch) {
	return action => {
		switch (action.type) {
			case FETCH_SCHOOLS:
				fetchSchools(action, dispatch);
				break;
			case UPDATE_SCHOOL:
				updateSchool(action, dispatch);
				break;
			case CREATE_SCHOOL:
				createSchool(action, dispatch);
				break;
			case DELETE_SCHOOL:
				deleteSchool(action, dispatch);
				break;
			default:
				dispatch(action);
				break;
		}
	};
}
