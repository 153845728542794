import {
	STORE_SKILLS,
	LOADING,
	DONE_LOADING,
	NOTIFY_SUCCESS,
	NOTIFY_ERROR,
	STORE_SKILL,
	UPDATING,
	DONE_UPDATING,
	ADD_SKILL,
	REMOVE_SKILL
} from "../types";
import { backend } from "../../../config";
import Request from "../../../helpers/Request";

function storeSkills(skills) {
	return skills;
}

function storeSkill(skills, skill) {
	let _skills = [...skills];
	const skillIndex = _skills.indexOf(
		_skills.find(_skill => _skill.name === skill.name)
	);
	_skills[skillIndex] = skill.data;
	return _skills;
}

function addSkill(skills, skill) {
	let _skills = [...skills];
	_skills.push(skill);
	return _skills;
}

function removeSkill(skills, skill) {
	return skills.filter(_skill => _skill.name !== skill.name);
}

function fetchSkills(action, dispatch) {
	dispatch({ type: LOADING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.skills}`,
		method: "GET",
		success: response => {
			if (response.ok) {
				dispatch({ type: STORE_SKILLS, payload: response.message });
				dispatch({ type: DONE_LOADING });
				return;
			}
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("skillActions => fetchSkills");
		}
	}).make();
}

function updateSkill(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.skills}/${
			action.payload.name
		}`,
		method: "PATCH",
		body: action.payload.data,
		success: response => {
			if (response.ok) {
				dispatch({
					type: STORE_SKILL,
					payload: {
						name: action.payload.name,
						data: response.message
					}
				});
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Skill updated!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("skillActions => updateSkill");
		}
	}).make();
}

function createSkill(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.skills}`,
		method: "POST",
		body: action.payload,
		success: response => {
			if (response.ok) {
				dispatch({ type: ADD_SKILL, payload: response.message });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Skill Created!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("skillActions => createSkill");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

function deleteSkill(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.skills}/${
			action.payload.name
		}`,
		method: "DELETE",
		noParse: true,
		success: response => {
			if (response.ok) {
				dispatch({ type: REMOVE_SKILL, payload: action.payload });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Skill Deleted!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("skillActions => deleteSkill");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

export {
	storeSkills,
	fetchSkills,
	updateSkill,
	storeSkill,
	createSkill,
	addSkill,
	deleteSkill,
	removeSkill
};
