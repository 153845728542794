import {
	STORE_SCHOOLS,
	LOADING,
	DONE_LOADING,
	NOTIFY_SUCCESS,
	NOTIFY_ERROR,
	STORE_SCHOOL,
	UPDATING,
	DONE_UPDATING,
	ADD_SCHOOL,
	REMOVE_SCHOOL
} from "../types";
import { backend } from "../../../config";
import Request from "../../../helpers/Request";

function storeSchools(schools) {
	return schools.map(school => {
		return {
			name: school.name,
			platformDefault: school.platform_default
		};
	});
}

function storeSchool(schools, school) {
	let _schools = [...schools];
	const schoolIndex = _schools.indexOf(
		_schools.find(_school => _school.name === school.name)
	);
	_schools[schoolIndex] = {
		name: school.data.name,
		platformDefault: school.data.platform_default
	};
	return _schools;
}

function addSchool(schools, school) {
	let _schools = [...schools];
	_schools.push({name:school.name, platformDefault: school.platform_default});
	return _schools;
}

function removeSchool(schools, school) {
	return schools.filter(_school => _school.name !== school.name);
}

function fetchSchools(action, dispatch) {
	dispatch({ type: LOADING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.schools}`,
		method: "GET",
		success: response => {
			if (response.ok) {
				dispatch({ type: STORE_SCHOOLS, payload: response.message });
				dispatch({ type: DONE_LOADING });
				return;
			}
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("schoolsActions => fetchSchools");
		}
	}).make();
}

function updateSchool(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.schools}/${action.payload.name}`,
		method: "PATCH",
		body: action.payload.data,
		success: response => {
			if (response.ok) {
				dispatch({
					type: STORE_SCHOOL,
					payload: {
						name: action.payload.name,
						data: response.message
					}
				});
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "School updated!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("schoolsActions => updateSchool");
		}
	}).make();
}

function createSchool(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.schools}`,
		method: "POST",
		body: action.payload,
		success: response => {
			if (response.ok) {
				dispatch({ type: ADD_SCHOOL, payload: response.message });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "School Created!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("schoolsActions => createSchool");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

function deleteSchool(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.schools}/${action.payload.name}`,
		method: "DELETE",
		noParse: true,
		success: response => {
			if (response.ok) {
				dispatch({ type: REMOVE_SCHOOL, payload: action.payload });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "School Deleted!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("schoolsActions => deleteSchool");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

export {
	storeSchools,
	fetchSchools,
	updateSchool,
	storeSchool,
	createSchool,
	addSchool,
	deleteSchool,
	removeSchool
};
