import { STORE_JOBSEEKERS } from "../types";
import { storeJobseekers } from "./jobseekersActions";

export default function JobseekersReducer(state, action) {
	switch (action.type) {
		case STORE_JOBSEEKERS:
			return storeJobseekers(action.payload);
		default:
			return state;
	}
}
