import React, { useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";

import Page from "./Page";
import AppContext from "./context/AppContext";
import ActionContext from "./context/ActionContext";
import {
	FETCH_ADMINS,
	UPDATE_ADMIN,
	CREATE_ADMIN,
	DELETE_ADMIN
} from "./context/types";

export default function AdminsPage(props) {
	const { admins, loading, updating } = useContext(AppContext);
	const dispatch = useContext(ActionContext);
	useEffect(() => {
		document.title = "Admins";
		dispatch({ type: FETCH_ADMINS });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Page header="Admins" location={props.location.pathname}>
			{!loading && (
				<MaterialTable
					title="Available Companies"
					columns={[
						{
							title: "Username*",
							field: "username"
						},
						{
							title: "Password*",
							field: "password",
							editComponent: props => (
								<TextField
									type="password"
									value={props.value ? props.value : ""}
									onChange={e =>
										props.onChange(e.target.value)
									}
								/>
							)
						}
					]}
					data={admins}
					editable={{
						onRowAdd: newData =>
							new Promise((resolve, reject) => {
								dispatch({
									type: CREATE_ADMIN,
									payload: newData
								});
								if (!updating) {
									resolve();
								}
							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve, reject) => {
								dispatch({
									type: UPDATE_ADMIN,
									payload: {
										username: oldData.username,
										data: newData
									}
								});
								if (!updating) {
									resolve();
								}
							}),
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								dispatch({
									type: DELETE_ADMIN,
									payload: oldData
								});
								if (!updating) {
									resolve();
								}
							})
					}}
					options={{
						actionsColumnIndex: -1
					}}
				/>
			)}
		</Page>
	);
}
