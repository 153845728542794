function storeError(state, response) {
	return {
		...state,
		type: "error",
		message: response.message,
		code: response.code,
		status: response.status,
		new: true
	};
}

function storeSuccess(state, response) {
	return {
		...state,
		type: "success",
		message: response.message,
		code: response.code,
		status: response.status,
		new: true
	};
}

function readNotification(state) {
	return {
		...state,
		new: false,
	};
}
function hideNotification(state) {
	return {
		...state,
		show: false,
		new: false,
	}
}

function showNotification(state) {
	return {
		...state,
		show: true
	}
}

export { storeError, storeSuccess, readNotification, hideNotification, showNotification };
