import { STORE_SKILLS, STORE_SKILL, ADD_SKILL, REMOVE_SKILL } from "../types";
import { storeSkills, storeSkill, addSkill, removeSkill } from "./skillsActions";

export default function SkillsReducer(state, action) {
	switch (action.type) {
		case STORE_SKILLS:
			return storeSkills(action.payload);
		case STORE_SKILL:
			return storeSkill(state, action.payload);
		case ADD_SKILL:
			return addSkill(state, action.payload);
		case REMOVE_SKILL: 
			return removeSkill(state, action.payload);
		default:
			return state;
	}
}
