class Request {
	/**
	 *@var {Object} props
	 * {
		url: "http//someurl.example", - MANDATORY
		method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" - MANDATORY
		headers: {Object} - OPTIONAL
		body: {Object} - OPTIONAL,
		noParse: {Boolean} - OPTIONAL - whether to parse the data to JSON - default false
		success: {Function} callback on request success - MANDATORY,
		fail: {Function}, callback on request fail - OPTIONAL
	 }
	 */
	credentials = "include";
	headers = {
		"Access-Control-Allow-Origin": "",
		Accept: "application/json",
		"Content-Type": "application/json"
	};
	requestData = {};
	noParse = false;

	constructor(props) {
		const checkMandatoryProps = props => {
			const mandatoryProps = ["success", "method", "url"];
			return (
				Object.keys(props).filter(function(elem) {
					return mandatoryProps.indexOf(elem) > -1;
				}).length === mandatoryProps.length
			);
		};
		if (!checkMandatoryProps(props)) {
			throw new Error("Missing mandatory props");
		}

		const getBaseUrl = url => {
			const urlParts = url.split("/");
			const protocol = urlParts[0];
			const host = urlParts[2];
			return `${protocol}//${host}`;
		};

		this.url = props.url;
		this.noParse = props.noParse ? props.noParse : this.noParse;
		this.success = props.success;
		this.requestData.credentials = props.credentials
			? props.credentials
			: this.credentials;
		this.requestData.headers = props.headers
			? { ...this.headers, ...props.headers }
			: this.headers;
		this.requestData.method = props.method;
		if (
			props.hasOwnProperty("body") &&
			props.body.constructor.name === "Object"
		) {
			this.requestData.body = JSON.stringify(props.body);
		}

		this.fail = props.fail ? props.fail : () => {};
		this.requestData.headers["Access-Control-Allow-Origin"] = getBaseUrl(
			props.url
		);
	}

	make = () => {
		fetch(this.url, this.requestData)
			.then(response => {
				if (this.noParse === true) {
					return { ok: response.ok, status: response.status };
				}
				return response.json().then(json => {
					return {
						...json,
						ok: response.ok,
						status: response.status
					};
				});
			})
			.then(response => {
				this.success(response);
			})
			.catch(error => {
				console.log(error);
				this.fail(error);
			});
	};
}

export default Request;
