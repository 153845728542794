const initialState = {
	auth: {
		user: {},
		isLogged: false,
		isLoading: true
	},
	notification: {
		type: undefined,
		message: undefined,
		code: undefined,
		status: undefined,
		new: false,
		show: true
	},
	companies: [],
	jobseekers: [],
	admins: [],
	skills: [],
	employers: [],
	schools: [],
	updating: false,
	loading: false
};

export default initialState;
