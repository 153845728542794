import { FETCH_COMPANIES, UPDATE_COMPANY } from "../types";
import { fetchCompanies, updateCompany } from "./companiesActions";

export default function CompaniesMiddleware(dispatch) {
	return action => {
		switch (action.type) {
			case FETCH_COMPANIES:
				fetchCompanies(action, dispatch);
				break;
			case UPDATE_COMPANY:
				updateCompany(action, dispatch);
				break;
			default:
				dispatch(action);
				break;
		}
	};
}
