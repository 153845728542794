import { STORE_EMPLOYERS, STORE_EMPLOYER, ADD_EMPLOYER, REMOVE_EMPLOYER } from "../types";
import { storeEmployers, storeEmployer, addEmployer, removeEmployer } from "./employersActions";

export default function EmployersReducer(state, action) {
	switch (action.type) {
		case STORE_EMPLOYERS:
			return storeEmployers(action.payload);
		case STORE_EMPLOYER:
			return storeEmployer(state, action.payload);
		case ADD_EMPLOYER:
			return addEmployer(state, action.payload);
		case REMOVE_EMPLOYER: 
			return removeEmployer(state, action.payload);
		default:
			return state;
	}
}
