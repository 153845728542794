import React, { useReducer } from "react";
import AppContext from "./components/context/AppContext";
import initialState from "./components/context/initialState";
import AppReducer from "./components/context/AppReducer";
import ActionContext from "./components/context/ActionContext";
import AsyncMiddleware from "./components/context/async/AsyncMiddleware";
import SystemNotification from "./components/notification/SystemNotification";

export default function AppState(props) {
	const [app, dispatch] = useReducer(AppReducer, initialState);

	return (
		<AppContext.Provider value={app}>
			<ActionContext.Provider value={AsyncMiddleware(dispatch)}>
				<SystemNotification>{props.children}</SystemNotification>
			</ActionContext.Provider>
		</AppContext.Provider>
	);
}
