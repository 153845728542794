import React, { useEffect, useContext } from "react";
import Page from "./Page";
import MaterialTable from "material-table";
import { permissionTypes, frozenStatus, locale } from "../config";
import AppContext from "./context/AppContext";
import ActionContext from "./context/ActionContext";
import { FETCH_JOBSEEKERS } from "./context/types";
export default function CompaniesPage(props) {
	const { jobseekers, loading } = useContext(AppContext);
	const dispatch = useContext(ActionContext);
	useEffect(() => {
		document.title = "Jobseekers";
		dispatch({ type: FETCH_JOBSEEKERS });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Page header="Jobseekers" location={props.location.pathname}>
			{!loading && (
				<MaterialTable
					title="Registered Jobseekers"
					columns={[
						{
							title: "Jobseeker Name",
							field: "name",
							editable: "never"
						},
						{
							title: "Email",
							field: "email",
							editable: "never"
						},
						{
							title: "Global Profile Access",
							field: "permissionType",
							lookup: permissionTypes
						},
						{
							title: "Activation Status",
							field: "frozen",
							lookup: frozenStatus,
							editable: "never"
						},
						{
							title: "Registration Date",
							field: "registrationDate",
							editable: "never"
						}
					]}
					data={jobseekers.map(jobseeker => {
						return {
							name: jobseeker.name,
							email: jobseeker.email,
							permissionType: jobseeker.permission_type,
							frozen: jobseeker.frozen,
							registrationDate: new Date(
								Date.parse(jobseeker.registration_date)
							).toLocaleDateString(locale)
						};
					})}
				/>
			)}
		</Page>
	);
}
