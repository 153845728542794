import {
	STORE_EMPLOYERS,
	LOADING,
	DONE_LOADING,
	NOTIFY_SUCCESS,
	NOTIFY_ERROR,
	STORE_EMPLOYER,
	UPDATING,
	DONE_UPDATING,
	ADD_EMPLOYER,
	REMOVE_EMPLOYER
} from "../types";
import { backend } from "../../../config";
import Request from "../../../helpers/Request";

function storeEmployers(employers) {
	return employers;
}

function storeEmployer(employers, employer) {
	let _employers = [...employers];
	const employerIndex = _employers.indexOf(
		_employers.find(_employer => _employer.name === employer.name)
	);
	_employers[employerIndex] = employer.data;
	return _employers;
}

function addEmployer(employers, employer) {
	let _employers = [...employers];
	_employers.push(employer);
	return _employers;
}

function removeEmployer(employers, employer) {
	return employers.filter(_employer => _employer.name !== employer.name);
}

function fetchEmployers(action, dispatch) {
	dispatch({ type: LOADING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.employers}`,
		method: "GET",
		success: response => {
			if (response.ok) {
				dispatch({ type: STORE_EMPLOYERS, payload: response.message });
				dispatch({ type: DONE_LOADING });
				return;
			}
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("employersActions => fetchEmployers");
		}
	}).make();
}

function updateEmployer(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.employers}/${
			action.payload.name
		}`,
		method: "PATCH",
		body: action.payload.data,
		success: response => {
			if (response.ok) {
				dispatch({
					type: STORE_EMPLOYER,
					payload: {
						name: action.payload.name,
						data: response.message
					}
				});
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Employer updated!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("employersActions => updateEmployer");
		}
	}).make();
}

function createEmployer(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.employers}`,
		method: "POST",
		body: action.payload,
		success: response => {
			if (response.ok) {
				dispatch({ type: ADD_EMPLOYER, payload: response.message });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Employer Created!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("employersActions => createEmployer");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

function deleteEmployer(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.employers}/${
			action.payload.name
		}`,
		method: "DELETE",
		noParse: true,
		success: response => {
			if (response.ok) {
				dispatch({ type: REMOVE_EMPLOYER, payload: action.payload });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Employer Deleted!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("employersActions => deleteEmployer");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

export {
	storeEmployers,
	fetchEmployers,
	updateEmployer,
	storeEmployer,
	createEmployer,
	addEmployer,
	deleteEmployer,
	removeEmployer
};
