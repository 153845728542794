import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/WarningRounded";
import { MTableToolbar } from "material-table";

const useStyles = makeStyles(theme => ({
	root: {
		display: "inline-flex",
		paddingLeft: theme.spacing(2)
	}
}));

export default function WarningInfo(props) {
	const classes = useStyles();
	return (
		<div>
			{Object.keys(props.toolbar).length > 0 && (
				<MTableToolbar {...props.toolbar} />
			)}
			<Typography variant="overline" classes={classes}>
				<WarningIcon color="error" />
				{props.message}
			</Typography>
		</div>
	);
}

WarningInfo.defaultProps = {
	message: "",
	toolbar: {}
};
