export const LOADING = "LOADING";
export const DONE_LOADING = "DONE_LOADING";
export const UPDATING = "UPDATING";
export const DONE_UPDATING = "DONE_UPDATING";
export const LOGIN_FETCH = "LOGIN_FETCH";
export const LOGIN_SUCCESSFULL =  "LOGIN_SUCCESSFULL";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const IS_LOGGED = "IS_LOGGED";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const LOGOUT = "LOGOUT";
export const NOTIFY_ERROR = "NOTIFY_ERROR";
export const NOTIFY_SUCCESS = "NOTIFY_SUCCESS";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const STORE_COMPANIES = "STORE_COMPANIES";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const STORE_COMPANY = "STORE_COMPANY";
export const FETCH_JOBSEEKERS = "FETCH_JOBSEEKERS";
export const STORE_JOBSEEKERS = "STORE_JOBSEEKERS";
export const FETCH_ADMINS = "FETCH_ADMINS";
export const STORE_ADMINS = "STORE_ADMINS";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const STORE_ADMIN = "STORE_ADMIN";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const ADD_ADMIN = "ADD_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const REMOVE_ADMIN = "REMOVE_ADMIN";
export const FETCH_SKILLS = "FETCH_SKILLS";
export const STORE_SKILLS = "STORE_SKILLS";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const STORE_SKILL = "STORE_SKILL";
export const CREATE_SKILL = "CREATE_SKILL";
export const ADD_SKILL = "ADD_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";
export const REMOVE_SKILL = "REMOVE_SKILL";
export const FETCH_EMPLOYERS = "FETCH_EMPLOYERS";
export const STORE_EMPLOYERS = "STORE_EMPLOYERS";
export const UPDATE_EMPLOYER = "UPDATE_EMPLOYER";
export const STORE_EMPLOYER = "STORE_EMPLOYER";
export const CREATE_EMPLOYER = "CREATE_EMPLOYER";
export const ADD_EMPLOYER = "ADD_EMPLOYER";
export const DELETE_EMPLOYER = "DELETE_EMPLOYER";
export const REMOVE_EMPLOYER = "REMOVE_EMPLOYER";
export const FETCH_SCHOOLS = "FETCH_SCHOOLS";
export const STORE_SCHOOLS = "STORE_SCHOOLS";
export const UPDATE_SCHOOL = "UPDATE_SCHOOL";
export const STORE_SCHOOL = "STORE_SCHOOL";
export const CREATE_SCHOOL = "CREATE_SCHOOL";
export const ADD_SCHOOL = "ADD_SCHOOL";
export const DELETE_SCHOOL = "DELETE_SCHOOL";
export const REMOVE_SCHOOL = "REMOVE_SCHOOL";