import AuthMiddleware from '../auth/AuthMiddleware';
import CompaniesMiddleware from '../companies/CompaniesMiddleware';
import JobseekersMiddleware from '../jobseekers/JobseekersMiddleware';
import AdminsMiddleware from '../admins/AdminsMiddleware';
import SkillsMiddleware from '../skills/SkillsMiddleware';
import EmployersMiddleware from '../employers/EmployersMiddleware';
import SchoolsMiddleware from '../schools/SchoolsMiddleware';

export default function AsyncMiddleware(dispatch) {
	return action => {
		AuthMiddleware(dispatch)(action);
		CompaniesMiddleware(dispatch)(action);
		JobseekersMiddleware(dispatch)(action);
		AdminsMiddleware(dispatch)(action);
		SkillsMiddleware(dispatch)(action);
		EmployersMiddleware(dispatch)(action);
		SchoolsMiddleware(dispatch)(action);
		return dispatch(action);

	}
}