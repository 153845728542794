import React, { useEffect, useContext } from "react";
import Page from "./Page";
import MaterialTable from "material-table";

import WarningInfo from "./notification/WarningInfo";
import AppContext from "./context/AppContext";
import ActionContext from "./context/ActionContext";
import {
	FETCH_SCHOOLS,
	UPDATE_SCHOOL,
	CREATE_SCHOOL,
	DELETE_SCHOOL
} from "./context/types";

export default function SchoolsPage(props) {
	const { schools, loading, updating } = useContext(AppContext);
	const dispatch = useContext(ActionContext);
	useEffect(() => {
		document.title = "Available Schools";
		dispatch({ type: FETCH_SCHOOLS });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Page header="Schools" location={props.location.pathname}>
			{!loading && (
				<MaterialTable
					title="Available Schools"
					columns={[
						{
							title: "Name*",
							field: "name"
						},
						{
							title: "Approved*",
							field: "platformDefault",
							type: "boolean"
						}
					]}
					data={schools}
					components={{
						Toolbar: props => {
							return (
								<WarningInfo
									toolbar={props}
									message="Be careful deleting rows as it may affect users information"
								/>
							);
						}
					}}
					editable={{
						onRowAdd: newData =>
							new Promise((resolve, reject) => {
								dispatch({
									type: CREATE_SCHOOL,
									payload: newData
								});
								if (!updating) {
									resolve();
								}
							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve, reject) => {
								dispatch({
									type: UPDATE_SCHOOL,
									payload: {
										name: oldData.name,
										data: newData
									}
								});
								if (!updating) {
									resolve();
								}
							}),
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								dispatch({
									type: DELETE_SCHOOL,
									payload: oldData
								});
								if (!updating) {
									resolve();
								}
							})
					}}
					options={{
						actionsColumnIndex: -1
					}}
				/>
			)}
		</Page>
	);
}
