import { LOGIN_FETCH, IS_LOGGED, LOGOUT } from "../types";
import { loginAttempt, isLogged, logout } from "./authActions";

export default function AuthMiddleware(dispatch) {
	return action => {
		switch (action.type) {
			case LOGIN_FETCH:
				loginAttempt(action, dispatch);
				break;
			case IS_LOGGED: {
				isLogged(action, dispatch);
				break;
			}
			case LOGOUT: {
				logout(action, dispatch);
				break;
			}
			default:
				dispatch(action);
				break;
		}
	};
}
