import {
	STORE_JOBSEEKERS,
	LOADING,
	DONE_LOADING,
	NOTIFY_ERROR
} from "../types";
import { backend } from "../../../config";
import Request from "../../../helpers/Request";

function storeJobseekers(jobseekers) {
	return jobseekers;
}

function fetchJobseekers(action, dispatch) {
	dispatch({ type: LOADING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.jobseekers}`,
		method: "GET",
		success: response => {
			if (response.ok) {
				dispatch({ type: STORE_JOBSEEKERS, payload: response.message });
				dispatch({ type: DONE_LOADING });
				return;
			}
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("jobseekersActions => fetchJobseekers");
		}
	}).make();
}

export { storeJobseekers, fetchJobseekers };
