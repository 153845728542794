import React, { useEffect, useContext } from "react";
import Page from "./Page";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import { companyTypes, frozenStatus } from "../config";
import AppContext from "./context/AppContext";
import ActionContext from "./context/ActionContext";
import { FETCH_COMPANIES, UPDATE_COMPANY } from "./context/types";
export default function CompaniesPage(props) {
	const dateToYMDString = date => {
		const d = date.getDate();
		const m = date.getMonth() + 1; //Month from 0 to 11
		const y = date.getFullYear();
		return (
			"" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d)
		);
	};
	const { companies, loading, updating } = useContext(AppContext);
	const dispatch = useContext(ActionContext);
	useEffect(() => {
		document.title = "Companies";
		dispatch({ type: FETCH_COMPANIES });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Page header="Companies" location={props.location.pathname}>
			{!loading && (
				<MaterialTable
					title="Available Companies"
					columns={[
						{
							title: "Company Name",
							field: "companyName",
							editable: "never"
						},
						{
							title: "Company Type",
							field: "companyType",
							lookup: companyTypes
						},
						{
							title: "Premium expiration",
							field: "expireDate",
							type: "date",
							editComponent: props => (
								<TextField
									type="date"
									value={props.value ? props.value : ""}
									inputProps={{
										min: dateToYMDString(new Date())
									}}
									disabled={
										companyTypes[
											props.rowData.companyType
										] !== companyTypes.free
											? false
											: true
									}
									onChange={e =>
										props.onChange(e.target.value)
									}
								/>
							)
						},
						{
							title: "Email",
							field: "companyEmail",
							editable: "never"
						},
						{
							title: "Activation Status",
							field: "frozen",
							lookup: frozenStatus,
							editable: "never"
						}
					]}
					data={companies.map(company => {
						return {
							companyName: company.company_name,
							companyType: company.company_type,
							companyEmail: company.company_email,
							frozen: company.frozen,
							expireDate: company.company_type_expiration
						};
					})}
					editable={{
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve, reject) => {
								dispatch({
									type: UPDATE_COMPANY,
									payload: newData
								});
								if (!updating) {
									resolve();
								}
							})
					}}
					options={{
						actionsColumnIndex: -1
					}}
				/>
			)}
		</Page>
	);
}
