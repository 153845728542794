import { STORE_ADMINS, STORE_ADMIN, ADD_ADMIN, REMOVE_ADMIN } from "../types";
import { storeAdmins, storeAdmin, addAdmin, removeAdmin } from "./adminsActions";

export default function AdminsReducer(state, action) {
	switch (action.type) {
		case STORE_ADMINS:
			return storeAdmins(action.payload);
		case STORE_ADMIN:
			return storeAdmin(state, action.payload);
		case ADD_ADMIN:
			return addAdmin(state, action.payload);
		case REMOVE_ADMIN: 
			return removeAdmin(state, action.payload);
		default:
			return state;
	}
}
