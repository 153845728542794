import { STORE_COMPANIES, STORE_COMPANY } from "../types";
import { storeCompanies, storeCompany } from "./companiesActions";

export default function CompaniesReducer(state, action) {
	switch (action.type) {
		case STORE_COMPANIES:
			return storeCompanies(action.payload);
		case STORE_COMPANY:
			return storeCompany(state, action.payload);
		default:
			return state;
	}
}
