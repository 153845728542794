import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import ActionContext from "./context/ActionContext";
import AppContext from "./context/AppContext";
import { LOGOUT } from "./context/types";
import { frontend } from "../config";

export default function LogoutPage(props) {
	const dispatch = useContext(ActionContext);
	const { auth } = useContext(AppContext);
	useEffect(() => {
		dispatch({ type: LOGOUT });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (!auth.isLogged) {
		return <Redirect to={frontend.route.login} />;
	}
	return "";
}
