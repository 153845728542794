import default_config from "./default_config";

default_config.backend.DOMAIN = "https://admin.match.careers/api";
default_config.frontend.DOMAIN = "https://admin.match.careers";

export const backend = default_config.backend;
export const frontend = default_config.frontend;
export const companyTypes = default_config.companyTypes;
export const frozenStatus = default_config.frozenStatus;
export const locale = default_config.locale;
export const permissionTypes = default_config.permissionTypes;
