import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemLink from "./routing/ListItemLink";
import BusinessIcon from "@material-ui/icons/BusinessTwoTone";
import BallotIcon from "@material-ui/icons/BallotOutlined";
import SchoolIcon from "@material-ui/icons/SchoolOutlined";
import HowToRegIcon from "@material-ui/icons/HowToRegOutlined";
import SecurityIcon from "@material-ui/icons/SecurityOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined";
import JobseekerIcon from '@material-ui/icons/WorkTwoTone';
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { frontend } from "../config";

const AdapterLink = React.forwardRef((props, ref) => (
	<Link innerRef={ref} {...props} />
));

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex"
	},
	drawer: {
		[theme.breakpoints.up("sm")]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	appBar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up("sm")]: {
			width: `calc(100% - ${drawerWidth}px)`
		}
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			display: "none"
		}
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth
	},
	header: {
		justifyContent: "space-between"
	},
	whiteLabel: {
		color: "white"
	},
	logoutIcon: {
		marginLeft: theme.spacing(1)
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	}
}));

function Page(props) {
	const { children, header, location } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const { companies, jobseekers, skills, employers, schools, admins, logout } = frontend.route;
	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen);
	}
	const drawer = (
		<div>
			<List>
				<ListItemLink
					to={companies}
					primary="Companies"
					icon={<BusinessIcon />}
					selected={location === companies}
				/>
				<ListItemLink
					to={jobseekers}
					primary="Jobseekers"
					icon={<JobseekerIcon />}
					selected={location === jobseekers}
				/>
				<ListItemLink
					to={skills}
					primary="Skills"
					icon={<BallotIcon />}
					selected={location === skills}
				/>
				<ListItemLink
					to={schools}
					primary="Schools"
					icon={<SchoolIcon />}
					selected={location === schools}
				/>
				<ListItemLink
					to={employers}
					primary="Employers"
					icon={<HowToRegIcon />}
					selected={location === employers}
				/>
				<ListItemLink
					to={admins}
					primary="Admins"
					icon={<SecurityIcon />}
					selected={location === admins}
				/>
			</List>
		</div>
	);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.header}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap>
						{header}
					</Typography>
					<Button
						component={AdapterLink}
						to={logout}
						classes={{ label: classes.whiteLabel }}
					>
						Logout
						<ExitToAppIcon className={classes.logoutIcon} />
					</Button>
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer} aria-label="mailbox folders">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={theme.direction === "rtl" ? "right" : "left"}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{children}
			</main>
		</div>
	);
}

Page.defaultProps = {
	children: {},
	header: "Match Careers Admin Area"
};

export default Page;
