import { FETCH_ADMINS, UPDATE_ADMIN, CREATE_ADMIN, DELETE_ADMIN } from "../types";
import { fetchAdmins, updateAdmin, createAdmin, deleteAdmin } from "./adminsActions";

export default function AdminsMiddleware(dispatch) {
	return action => {
		switch (action.type) {
			case FETCH_ADMINS:
				fetchAdmins(action, dispatch);
				break;
			case UPDATE_ADMIN:
				updateAdmin(action, dispatch);
				break;
			case CREATE_ADMIN:
				createAdmin(action, dispatch);
				break;
			case DELETE_ADMIN:
				deleteAdmin(action, dispatch);
				break;
			default:
				dispatch(action);
				break;
		}
	};
}
