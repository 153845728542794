import {
	STORE_ADMINS,
	LOADING,
	DONE_LOADING,
	NOTIFY_SUCCESS,
	NOTIFY_ERROR,
	STORE_ADMIN,
	UPDATING,
	DONE_UPDATING,
	ADD_ADMIN,
	REMOVE_ADMIN
} from "../types";
import { backend } from "../../../config";
import Request from "../../../helpers/Request";

function storeAdmins(admins) {
	return admins;
}

function storeAdmin(admins, admin) {
	let _admins = [...admins];
	const adminIndex = _admins.indexOf(
		_admins.find(_admin => _admin.username === admin.username)
	);
	_admins[adminIndex] = admin.data;
	return _admins;
}

function addAdmin(admins, admin) {
	let _admins = [...admins];
	_admins.push(admin);
	return _admins;
}

function removeAdmin(admins, admin) {
	return admins.filter(_admin => _admin.username !== admin.username);
}

function fetchAdmins(action, dispatch) {
	dispatch({ type: LOADING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.admins}`,
		method: "GET",
		success: response => {
			if (response.ok) {
				dispatch({ type: STORE_ADMINS, payload: response.message });
				dispatch({ type: DONE_LOADING });
				return;
			}
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("adminsActions => fetchAdmins");
		}
	}).make();
}

function updateAdmin(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.admins}/${
			action.payload.username
		}`,
		method: "PATCH",
		body: action.payload.data,
		success: response => {
			if (response.ok) {
				dispatch({
					type: STORE_ADMIN,
					payload: {
						username: action.payload.username,
						data: response.message
					}
				});
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Admin updated!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("adminActions => updateAdmin");
		}
	}).make();
}

function createAdmin(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.admins}`,
		method: "POST",
		body: action.payload,
		success: response => {
			if (response.ok) {
				dispatch({ type: ADD_ADMIN, payload: response.message });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Admin Created!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("adminActions => createAdmin");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

function deleteAdmin(action, dispatch) {
	dispatch({ type: UPDATING });
	new Request({
		url: `${backend.DOMAIN}${backend.api.admins}/${
			action.payload.username
		}`,
		method: "DELETE",
		noParse: true,
		success: response => {
			if (response.ok) {
				dispatch({ type: REMOVE_ADMIN, payload: action.payload });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Admin Deleted!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("adminActions => deleteAdmin");
			dispatch({
				type: NOTIFY_ERROR,
				payload: { ...error, message: "Server error" }
			});
		}
	}).make();
}

export {
	storeAdmins,
	fetchAdmins,
	updateAdmin,
	storeAdmin,
	createAdmin,
	addAdmin,
	deleteAdmin,
	removeAdmin
};
