import React, { useEffect } from "react";
import Page from "./Page";
import Typography from "@material-ui/core/Typography";

export default function AdminPanel(props) {
	useEffect(() => {
		document.title = "Admin Area";
	}, []);
	return (
		<Page>
			<Typography paragraph>
				This is the admin area where you can manage and edit accounts,
				available skills, schools, etc...
			</Typography>
		</Page>
	);
}
