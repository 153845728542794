import React, { useMemo, forwardRef } from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

export default function ListItemLink(props) {
	const { icon, primary, to, selected } = props;

	const renderLink = useMemo(
		() =>
			forwardRef((itemProps, ref) => (
				// with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
				<Link to={to} {...itemProps} ref={ref} />
			)),
		[to]
	);

	return (
		<li>
			<ListItem button component={renderLink} selected={selected}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
}
