import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AppState from "./AppState";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import UnauthenticatedRoute from "./components/routing/UnauthenticatedRoute";
import LoginPage from "./components/LoginPage";
import AdminPanel from "./components/AdminPanel";
import CompaniesPage from "./components/CompaniesPage";
import JobseekersPage from "./components/JobseekersPage";
import SkillsPage from "./components/SkillsPage";
import SchoolsPage from "./components/SchoolsPage";
import EmployersPage from "./components/EmployersPage";
import AdminsPage from "./components/AdminsPage";
import LogoutPage from "./components/LogoutPage";
import { frontend } from "./config";

export default function App() {
  return (
    <AppState>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute
            path={frontend.route.home}
            component={AdminPanel}
            exact
          />
          <ProtectedRoute
            path={frontend.route.companies}
            component={CompaniesPage}
            exact
          />
          <ProtectedRoute
            path={frontend.route.jobseekers}
            component={JobseekersPage}
            exact
          />
          <ProtectedRoute
            path={frontend.route.skills}
            component={SkillsPage}
            exact
          />
          <ProtectedRoute
            path={frontend.route.schools}
            component={SchoolsPage}
            exact
          />
          <ProtectedRoute
            path={frontend.route.employers}
            component={EmployersPage}
            exact
          />
          <ProtectedRoute
            path={frontend.route.admins}
            component={AdminsPage}
            exact
          />
          <ProtectedRoute
            path={frontend.route.logout}
            component={LogoutPage}
            exact
          />
          <UnauthenticatedRoute
            path={frontend.route.login}
            component={LoginPage}
            exact
          />
          <Route component={props => <h1>Page not found!</h1>} />
        </Switch>
      </BrowserRouter>
    </AppState>
  );
}
