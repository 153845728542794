import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import AppContext from "../context/AppContext";
import ActionContext from "../context/ActionContext";
import { HIDE_NOTIFICATION, IS_LOGGED } from "../context/types";
import { frontend } from "../../config";
export default function UnauthenticatedRoute({
	component: Component,
	redirect,
	...rest
}) {
	const { auth, notification } = useContext(AppContext);
	const dispatch = useContext(ActionContext);
	useEffect(() => {
		if (!auth.isLogged) {
			if (!notification.new) {
				dispatch({ type: HIDE_NOTIFICATION });
			}
			dispatch({ type: IS_LOGGED });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Route
			{...rest}
			render={props =>
				auth.isLogged === false ? (
					<Component {...props} />
				) : (
					<Redirect to={redirect} />
				)
			}
		/>
	);
}

UnauthenticatedRoute.defaultProps = {
	redirect: frontend.route.home
};
