import {
	LOGIN_FETCH,
	LOGIN_SUCCESSFULL,
	LOGIN_FAILURE,
	DONE_LOADING,
	NOTIFY_SUCCESS,
	NOTIFY_ERROR,
	SHOW_NOTIFICATION,
	SESSION_EXPIRED
} from "../types";
import { backend } from "../../../config";
import Request from "../../../helpers/Request";

function loginStore(state, user) {
	return {
		...state,
		user: user
	};
}

function loginSuccess(state) {
	return {
		...state,
		isLogged: true
	};
}

function loginFailure(state, errorMessage) {
	return {
		...state,
		isLogged: false
	};
}

function loginAttempt(action, dispatch) {
	new Request({
		url: `${backend.DOMAIN}${backend.api.login}`,
		method: "POST",
		body: {
			username: action.payload.username,
			password: action.payload.password
		},
		success: response => {
			dispatch({ type: SHOW_NOTIFICATION });
			if (response.ok) {
				dispatch(action);
				dispatch({ type: LOGIN_SUCCESSFULL });
				dispatch({ type: DONE_LOADING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Login Successful!" }
				});
				return;
			}
			dispatch({ type: LOGIN_FAILURE, payload: response.message });
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("authActions => logginAttempt");
		}
	}).make();
}

function isLogged(action, dispatch) {
	new Request({
		url: `${backend.DOMAIN}${backend.api.checkSession}`,
		method: "GET",
		success: response => {
			if (response.ok) {
				dispatch({ type: LOGIN_FETCH, payload: response.message });
				dispatch({ type: LOGIN_SUCCESSFULL });
				dispatch({ type: DONE_LOADING });
				return;
			}
			dispatch({ type: LOGIN_FAILURE, payload: response.message });
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("authActions => isLogged");
		}
	}).make();
}

function logout(action, dispatch) {
	new Request({
		url: `${backend.DOMAIN}${backend.api.logout}`,
		method: "GET",
		noParse: true,
		success: response => {
			if (response.ok) {
				dispatch({ type: SESSION_EXPIRED });
				return;
			}
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("authActions => isLogged");
		}
	}).make();
}

export { loginStore, loginSuccess, loginFailure, loginAttempt, isLogged, logout };
