import { FETCH_JOBSEEKERS } from "../types";
import { fetchJobseekers } from "./jobseekersActions";

export default function JobseekersMiddleware(dispatch) {
	return action => {
		switch (action.type) {
			case FETCH_JOBSEEKERS:
				fetchJobseekers(action, dispatch);
				break;
			default:
				dispatch(action);
				break;
		}
	};
}
