import { LOADING, DONE_LOADING } from "../types";

export default (state, action) => {
	switch (action.type) {
		case LOADING:
			return true;
		case DONE_LOADING:
			return false;
		default:
			return state;
	}
};
