import {
	STORE_COMPANIES,
	LOADING,
	DONE_LOADING,
	NOTIFY_SUCCESS,
	NOTIFY_ERROR,
	STORE_COMPANY,
	UPDATING,
	DONE_UPDATING
} from "../types";
import { backend } from "../../../config";
import Request from "../../../helpers/Request";

function storeCompanies(companies) {
	return companies;
}

function storeCompany(companies, company) {
	let _companies = [...companies];
	const companyIndex = companies.indexOf(
		companies.find(
			_company => _company.company_name === company.company_name
		)
	);
	_companies[companyIndex].company_type = company.company_type;
	_companies[companyIndex].company_type_expiration =
		company.company_type_expiration;
	return _companies;
}

function fetchCompanies(action, dispatch) {
	dispatch({ type: LOADING });

	new Request({
		url: `${backend.DOMAIN}${backend.api.companies}`,
		method: "GET",
		success: response => {
			if (response.ok) {
				dispatch({ type: STORE_COMPANIES, payload: response.message });
				dispatch({ type: DONE_LOADING });
				return;
			}
			dispatch({ type: DONE_LOADING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("companiesActions => fetchCompanies");
		}
	}).make();
}

function updateCompany(action, dispatch) {
	dispatch({ type: UPDATING });
	const { companyName, ...rest } = action.payload;
	new Request({
		url: `${backend.DOMAIN}${backend.api.companies}/${
			action.payload.companyName
		}`,
		method: "PATCH",
		body: rest,
		success: response => {
			if (response.ok) {
				dispatch({ type: STORE_COMPANY, payload: response.message });
				dispatch({ type: DONE_UPDATING });
				dispatch({
					type: NOTIFY_SUCCESS,
					payload: { ...response, message: "Company updated!" }
				});
				return;
			}
			dispatch({ type: DONE_UPDATING });
			dispatch({ type: NOTIFY_ERROR, payload: response });
		},
		fail: error => {
			console.log("companiesActions => fetchCompanies");
		}
	}).make();
}

export { storeCompanies, fetchCompanies, updateCompany, storeCompany };
