import {
	SESSION_EXPIRED,
	NOTIFY_ERROR,
	NOTIFY_SUCCESS,
	READ_NOTIFICATION,
	SHOW_NOTIFICATION,
	HIDE_NOTIFICATION
} from "../types";
import {
	storeError,
	storeSuccess,
	readNotification,
	showNotification,
	hideNotification
} from "./notificationActions";

export default (state, action) => {
	switch (action.type) {
		case NOTIFY_ERROR:
			return storeError(state, action.payload);
		case NOTIFY_SUCCESS:
			return storeSuccess(state, action.payload);
		case READ_NOTIFICATION:
			return readNotification(state);
		case HIDE_NOTIFICATION:
			return hideNotification(state);
		case SHOW_NOTIFICATION:
			return showNotification(state);
		case SESSION_EXPIRED:
			return {
				type: "error",
				message: "Session expired",
				code: "0001",
				status: 401,
				new: true,
				show: true
			};
		default:
			return state;
	}
};
