import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import AppContext from "../context/AppContext";
import ActionContext from "../context/ActionContext";
import { IS_LOGGED } from "../context/types";
import { frontend } from "../../config";

export default function ProtectedRoute({
	component: Component,
	redirect,
	...rest
}) {
	const { auth } = useContext(AppContext);
	const dispatch = useContext(ActionContext);

	useEffect(() => {
		dispatch({ type: IS_LOGGED });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rest.location.pathname]);
	if (auth.isLoading) {
		return "";
	}

	return (
		<Route
			{...rest}
			render={props =>
				auth.isLogged === true ? (
					<Component {...props} />
				) : (
					<Redirect to={redirect} />
				)
			}
		/>
	);
}

ProtectedRoute.defaultProps = {
	redirect: frontend.route.login
};
