import { FETCH_EMPLOYERS, UPDATE_EMPLOYER, CREATE_EMPLOYER, DELETE_EMPLOYER } from "../types";
import { fetchEmployers, updateEmployer, createEmployer, deleteEmployer } from "./employersActions";

export default function EmployersMiddleware(dispatch) {
	return action => {
		switch (action.type) {
			case FETCH_EMPLOYERS:
				fetchEmployers(action, dispatch);
				break;
			case UPDATE_EMPLOYER:
				updateEmployer(action, dispatch);
				break;
			case CREATE_EMPLOYER:
				createEmployer(action, dispatch);
				break;
			case DELETE_EMPLOYER:
				deleteEmployer(action, dispatch);
				break;
			default:
				dispatch(action);
				break;
		}
	};
}
