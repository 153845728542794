import { STORE_SCHOOLS, STORE_SCHOOL, ADD_SCHOOL, REMOVE_SCHOOL } from "../types";
import { storeSchools, storeSchool, addSchool, removeSchool } from "./schoolsActions";

export default function SchoolsReducer(state, action) {
	switch (action.type) {
		case STORE_SCHOOLS:
			return storeSchools(action.payload);
		case STORE_SCHOOL:
			return storeSchool(state, action.payload);
		case ADD_SCHOOL:
			return addSchool(state, action.payload);
		case REMOVE_SCHOOL: 
			return removeSchool(state, action.payload);
		default:
			return state;
	}
}
