import { UPDATING, DONE_UPDATING } from "../types";

export default (state, action) => {
	switch (action.type) {
		case UPDATING:
			return true;
		case DONE_UPDATING:
			return false;
		default:
			return state;
	}
};
