/**
 * Copy the file into config.js
 * Modify config.js for those configurations to make effect
 * Update this file with each new field addition to config.js
 */
const DOMAIN = "localhost";

module.exports = {
    backend: {
        DOMAIN: "http://" + DOMAIN + ":6999",
        api: {
            checkSession: "/session",
            login: "/login",
            logout: "/logout",
            companies: "/companies",
            jobseekers: "/jobseekers",
            admins: "/admins",
            skills: "/skills",
            employers: "/employers",
            schools: "/schools"
        }
    },
    frontend: {
        DOMAIN: "http://" + DOMAIN + ":3333",
        route: {
            login: "/login",
            logout: "/logout",
            home: "/",
            companies: "/companies",
            jobseekers: "/jobseekers",
            skills: "/skills",
            schools: "/schools",
            employers: "/employers",
            admins: "/admins",
            error: "/error"
        }
    },
    companyTypes: {
        free: "Free",
        gold: "Gold",
        platinum: "Platinum"
    },
    permissionTypes: {
        none: "No Access",
        limited: "Partial/Visible Only Skills",
        full: "Full Access to Profile Info"
    },
    frozenStatus: {
        no: "Active",
        not_activated: "Pending Activation"
    },
    locale: "en-DE"
};
