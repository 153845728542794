import React, { Fragment, useContext } from "react";
import ActionContext from "../context/ActionContext";
import AppContext from "../context/AppContext";
import { READ_NOTIFICATION, SESSION_EXPIRED } from "../context/types";
import Popup from "./Popup";

export default function SystemNotification(props) {
	const dispatch = useContext(ActionContext);
	const { notification, auth } = useContext(AppContext);
	const { children } = props;
	const handleClose = (event, reason) => {
		dispatch({ type: READ_NOTIFICATION });
	};
	if (notification.status === 401 && auth.isLogged === true) {
		dispatch({ type: SESSION_EXPIRED });
	}
	return (
		<Fragment>
			{children}
			<Popup
				handleClose={handleClose}
				open={notification.new && notification.show}
				message={notification.message}
				variant={notification.type}
				autoHideDuration={2300}
			/>
		</Fragment>
	);
}
