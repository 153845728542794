import { FETCH_SKILLS, UPDATE_SKILL, CREATE_SKILL, DELETE_SKILL } from "../types";
import { fetchSkills, updateSkill, createSkill, deleteSkill } from "./skillsActions";

export default function SkillsMiddleware(dispatch) {
	return action => {
		switch (action.type) {
			case FETCH_SKILLS:
				fetchSkills(action, dispatch);
				break;
			case UPDATE_SKILL:
				updateSkill(action, dispatch);
				break;
			case CREATE_SKILL:
				createSkill(action, dispatch);
				break;
			case DELETE_SKILL:
				deleteSkill(action, dispatch);
				break;
			default:
				dispatch(action);
				break;
		}
	};
}
