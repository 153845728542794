import AuthReducer from "./auth/AuthReducer";
import NotificationReducer from "./notification/NotificationReducer";
import AsyncReducer from "./async/AsyncReducer";
import UpdatingReducer from "./async/UpdatingReducer";
import CompaniesReducer from "./companies/CompaniesReducer";
import JobseekersReducer from "./jobseekers/JobseekersReducer";
import AdminsReducer from "./admins/AdminsReducer";
import SkillsReducer from "./skills/SkillsReducer";
import EmployersReducer from "./employers/EmployersReducer";
import SchoolsReducer from "./schools/SchoolsReducer";

export default (state, action) => {
	return {
		auth: AuthReducer(state.auth, action),
		notification: NotificationReducer(state.notification, action),
		companies: CompaniesReducer(state.companies, action),
		jobseekers: JobseekersReducer(state.jobseekers, action),
		admins: AdminsReducer(state.admins, action),
		skills: SkillsReducer(state.skills, action),
		employers: EmployersReducer(state.employers, action),
		schools: SchoolsReducer(state.schools, action),
		loading: AsyncReducer(state.loading, action),
		updating: UpdatingReducer(state.updating, action)
	};
};
